"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import { Divider, FormControl, FormHelperText, Grid, TextField, Typography, useTheme } from "@mui/material";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import { useWithdrawStore } from "@/stores/withdrawStore";
import { useSnackbar } from "notistack";
import { useAppModalStore } from "@/stores/appModalStore";
import numeral from "numeral";
import { LoadingButton } from "@mui/lab";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";
import useMe from "@/hooks/fetchers/useMe";
import { Stack } from "@mui/system";
import { useConfigStore } from "@/stores/configStore";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

type Inputs = {
  amount: number;
};

const schema = yup.object().shape({
  amount: yup
    .number()
    .required("กรุณากรอกจำนวนเงิน")
});

export default function WithdrawForm() {
  const theme = useTheme();
  const [user] = useAuthStore((state) => [state.user], shallow);
  const [withdraw] = useWithdrawStore((state) => [state.withdraw], shallow);
  const [loading, setLoading] = React.useState(false);
  const [setOpen, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const { refresh: refreshPro, data: promotion } = usePromotionHistory();
  const { refresh: refreshMe } = useMe();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    reset
  } = useForm<Inputs>({ resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoading(true);
    const amount = numeral(data.amount).value() || 0;

    if((user?.balance || 0) < data.amount){
      enqueueSnackbar("ยอดเงินคงเหลือไม่เพียงพอ กรุณาลองใหม่อีกครั้ง.", {
        variant: "error",
      });
      setLoading(false);
      return
    }

    await withdraw(amount)
      .then((res: any) => {
        if (res?.errMessage) {
          enqueueSnackbar(res.errMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          setLoading(false);
        } else {
          enqueueSnackbar("สำเร็จ", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          refreshPro();
          refreshMe();
          setOpen(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
        setLoading(false);
      });
  };

  React.useEffect(() => {
    reset({
      amount: parseInt(numeral(user?.balance).format('0'), 10)
    });
  }, [])

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography align="center" color={(theme) => theme.gradient[900]}>จำนวนเงินทั้งหมด</Typography>
            <Divider
              sx={{
                // mt: -1,
                borderColor: theme.palette.primary.main,
                borderRadius: "10px",
                borderWidth: "1px",
                width: "20px",
              }}
            />
            <Box flex="1" display={"flex"} justifyContent={"start"} alignItems={"center"} mt={2} sx={{ width: '100%' }}>
              <FormControl fullWidth>
                <Controller
                  name="amount"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextField
                      id="username"
                      autoFocus
                      autoComplete="off"
                      inputProps={{
                        min: 0,
                        sx: {
                          textAlign: 'center',
                          fontSize: '1.25rem',
                          fontWeight: 700,
                          color: (theme: any) => theme.gradient[920],
                          borderColor: (theme: any) => theme.gradient[920],
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderRadius: "16px",
                        }
                      }}
                      sx={{
                        ...(errors.amount && {
                          borderColor: (theme) => theme.gradient[920] || "error.main",
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderRadius: "16px",
                          "& .MuiInputLabel-root": {
                            color: (theme) => theme.gradient[920] || "error.main",
                          }
                        })
                      }}
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/\D/g, "");
                        onChange(numericValue);
                      }}
                      error={Boolean(errors.amount)}
                      placeholder="จำนวนเงิน"
                    />
                  )}
                />
                {errors.amount && (
                  <FormHelperText
                    sx={{
                      ...(errors.amount && {
                        borderColor: (theme) => theme.gradient[920] || "error.main",
                        color: (theme) => "error.main",
                        ml: 0
                      })
                    }}>
                    {`**${errors.amount.type === "typeError" ? 'กรุณากรอกจำนวนเงิน' : errors.amount.message}`}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={12}>
          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              fontSize: '1.5rem',
              fontWeight: 700,
              color: "#fff",
              background: "linear-gradient(330deg, rgba(133,6,6,1) 0%, rgba(193,0,0,1) 40%, rgba(255,36,36,1) 100%);",
              height: "50px",
              "&:hover": {
                color: '#fff',
                background: "linear-gradient(330deg, rgba(133,6,6,1) 0%, rgba(193,0,0,1) 40%, rgba(255,36,36,1) 100%);",
              },
              // borderRadius: 0,
              // textShadow: "1px 1px 4px rgba(0, 0, 0, 0.4)",
            }}
          >
            แจ้งถอน
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
}
