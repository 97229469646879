import WithdrawForm from "@/sections/WithdrawForm";
import WithdrawFormAll from "@/sections/WithdrawFormAll";
import CopyButton from "@/components/ui/CopyButton";
import { useAuthStore } from "@/stores/authStore";
import { ContentCopy, Info } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Chip,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import { blue, grey, purple } from "@mui/material/colors";
import Image from "next/image";
import React from "react";
import { shallow } from "zustand/shallow";
import { useConfigStore } from "@/stores/configStore";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";

type Props = {};

const Withdraw = (props: Props) => {
  const theme = useTheme();
  const [user] = useAuthStore((state) => [state.user], shallow);
  const [config] = useConfigStore((state) => [state.config], shallow);
  const { data: promotion } = usePromotionHistory();

  return (
    <Box>
      {/* <Typography variant="h6">เติมเครดิต</Typography> */}
      <Divider>
        <Chip
          label="แจ้งถอนเครดิต"
          color="primary"
          size="medium"
          style={{ fontSize: "18px", fontWeight: 700 }}
        />
      </Divider>
      {/* <Box mt={2}>
        <Alert
          variant="outlined"
          severity="success"
          color="warning"
          component={Box}
          sx={{
            border: (theme) => `1px solid ${theme.name === 'preset14' ? "#ffe2b7" : (theme.gradient[911] ? theme.gradient[911] : theme.gradient[900] ?? theme.palette.success.main)}`,
            "& .MuiAlert-icon": {
              color: (theme) => theme.name === 'preset14' ? "#ffe2b7" : (theme.gradient[911] ? theme.gradient[911] : theme.gradient[900] ?? theme.palette.success.main),
            },
            "& .MuiAlert-message": {
              color: (theme) => theme.name === 'preset14' ? "#ffe2b7" : (theme.gradient[911] ? theme.gradient[911] : theme.gradient[900] ?? theme.palette.success.main),
              borderLeft: (theme) => `1px solid ${theme.name === 'preset14' ? "#ffe2b7" : (theme.gradient[911] ? theme.gradient[911] : theme.gradient[900] ?? theme.palette.success.main)}`,
              paddingLeft: 2,
            },
          }}
        >
          <AlertTitle
            sx={{
              fontWeight: 700,
              color: (theme) => theme.gradient[900]
            }}
          >
            ขั้นตอนการถอนเครดิต
          </AlertTitle >
          {
            (config?.is_withdraw_all || promotion) ? (
              <>
                1). จะทำการถอนเงินทั้งหมดออกจากกระเป๋า <br />
                2). กด <strong>"แจ้งถอน"</strong> และรอการตรวจสอบจากแอดมิน <br />
              </>
            ) : (
              <>
                1). จะทำการถอนเงินโดยระบุจำนวนออกจากกระเป๋า <br />
                2). กด <strong>"แจ้งถอน"</strong> และรอการตรวจสอบจากแอดมิน <br />
              </>
            )
          }
        </Alert>
      </Box> */}
      <Box borderRadius={1} mt={2}>
        <Box
          display="flex"
          flexDirection={"column"}
          alignItems={"center"}
          mb={2}
        >
          <Box
            flex="1"
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
          >
            <Image
              // src={`/assets/bank-logo/${user?.bank_code}.png`}
              src={`${user?.bank_logo || ""}`}
              width={84}
              height={84}
              alt={`${user?.bank_code} BANK`}
            />
          </Box>
          <Typography
            variant="button"
            // color={grey[500]}
            lineHeight={1.5}
            mt={1}
            sx={{ color: theme => theme.gradient[900] }}
          >
            {user?.bank_name}
          </Typography>
          <Typography variant="body1" lineHeight={0} sx={{ color: theme => theme.gradient[900] }}>
            <Typography
              component={"span"}
              color={(theme) => theme.gradient[910] || theme.palette.primary.main}
              fontWeight={600}
            >
              เลขที่บัญชี:{" "}
            </Typography>
            {user?.account_number}
          </Typography>
          <Typography variant="body1" lineHeight={0} sx={{ color: theme => theme.gradient[900] }}>
            <Typography
              component={"span"}
              color={(theme) => theme.gradient[910] || theme.palette.primary.main}
              fontWeight={600}
            >
              ชื่อบัญชี:{" "}
            </Typography>
            {user?.fullname}
          </Typography>
        </Box>
        <Box mt={1}>
          {
            (config?.is_withdraw_all || promotion)
              ? <WithdrawFormAll />
              : <WithdrawForm />
          }
        </Box>
        <Box mt={2}>
          <Alert
            // sx={{ color: theme => theme.gradient[910] || theme.palette.warning.main }}
            icon={<Info fontSize="inherit" />}
            variant="outlined"
            severity="warning"
            sx={{
              // backgroundColor: (theme) => theme.gradient[930] ? theme.gradient[930] : "#1a2c38"
              background: 'linear-gradient(-325deg, rgba(250, 173, 20, 0.15) 100%, rgba(250, 173, 20, 0))'
            }}
            // sx={{
            //   borderColor: theme => theme.gradient[910] || theme.gradient[912] || theme.palette.warning.main,
            //   color: theme => theme.gradient[910] || theme.gradient[912] || theme.palette.warning.main,
            //   "& .MuiAlert-icon": {
            //     color: (theme) =>  theme.gradient[910] || theme.gradient[912] || theme.palette.warning.main,
            //   }, 
            // }}
            component={Box}
          >
            <AlertTitle sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
              {
                (config?.is_withdraw_all || promotion)
                  ? `ระบบจะโอนเงินเข้าบัญชีที่สมาชิกใช้สมัครเท่านั้น โดยจะทำการถอนเงินทั้งหมดออกจากกระเป๋าเงินจะเข้าโดยอัตโนมัติ`
                  : `ระบบจะโอนเงินเข้าบัญชีที่สมาชิกใช้สมัครเท่านั้น โดยจะทำการถอนเงินระบุจำนวนออกจากกระเป๋าเงินจะเข้าโดยอัตโนมัติ`
              }
            </AlertTitle>
          </Alert>
        </Box>
      </Box>
    </Box>
  );
};

export default Withdraw;
