import { fetcherAuth } from "@/libs/utils";
import { useAuthStore } from "@/stores/authStore";
import useSWR from "swr";
import { shallow } from "zustand/shallow";

type historyList = {
    deposits: any;
    withdraws: any;
    total_deposit: any;
    total_withdraw: any;
};

type historyListReturn = {
  data: historyList | null;
  isLoading: boolean;
  isError: any;
};

export default function useHistoryList(): historyListReturn {
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
  const { data, error } = useSWR<historyListReturn>(
    isLoggedIn ? `/api/v1/users/getdepositandwithdraw` : null,
    fetcherAuth
  );

  return {
    data: data?.data || null,
    isLoading: !error && !data,
    isError: error,
  };
}
