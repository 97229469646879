"use client";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";

import numeral from "numeral";
import { useSnackbar } from "notistack";

// mui
import Grid from "@mui/material/Unstable_Grid2";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import {
  Card,
  CardActionArea,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Typography,
  Alert,
  AlertTitle,
  Box,
  useTheme
} from "@mui/material";
import { Stack, useMediaQuery } from "@mui/system";
import { LoadingButton } from "@mui/lab";

// components
import TermsDialog from "@/sections/cashback/TermsDialog";
import SnackbarWithIcon from "@/components/snack-bar/SnackbarWithIcon";

// fetch data
import fetcher from "@/libs/fetcher";

// types
import {
  CashbackData,
  ApiResponseCashback,
  ApiResponseClaimCashbackData,
} from "@/@types/refound";
import { SnackbarOrigin } from "@mui/material/Snackbar";
import useMe from "@/hooks/fetchers/useMe";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";

import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";
import { Info } from "@mui/icons-material";

interface State extends SnackbarOrigin { }

const gameType = [
  {
    type: 'slot',
    label: 'สล็อต',
    icon: '/assets/icons/categories/slot_new.png'
  },
  {
    type: 'casino',
    label: 'คาสิโน',
    icon: '/assets/icons/categories/casino_new.png'
  },
  {
    type: 'sport',
    label: 'กีฬา',
    icon: '/assets/icons/categories/sport_new.png'
  },
  {
    type: 'lotto',
    label: 'หวย',
    icon: '/assets/icons/categories/huay_new.png'
  }, {
    type: 'fishing',
    label: 'ยิงปลา',
    icon: '/assets/icons/categories/fishing_new.png'
  },
]

const Cashback = (props: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();
  const { refresh } = useMe();
  const { refresh: refreshPromotion } = usePromotionHistory();
  const [loading, setLoading] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const [state, setState] = useState<State>({
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal } = state;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [cashback, setCashback] = useState<number>(0);
  const [cashbackProfit, setcashBackProfit] = useState<string>("0");
  const [config] = useConfigStore((state) => [state.config], shallow);

  // const [cashbackDayofweek, setCashbackDayofweek] = useState<number>(0);
  // const [dayOfWeek, setDayOfWeek] = useState<number>(0);
  // const [dayText, setDayText] = useState<string>("");

  const [cashbackData, setCashbackData] = useState<any | null>(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string>("An error occurred!");
  const [severity, setSeverity] = useState<
    "success" | "info" | "warning" | "error"
  >("error");
  const fetchCashback = async (): Promise<void> => {
    setLoading(true);
    const response: ApiResponseCashback = await fetcher(
      !props?.cashback_type
        ? "/api/v2/users/cashback"
        : `/api/v2/users/cashback-type?cashback_type=${props?.cashback_type}`
    );
    const result = response.data;
    if (result) {
      setCashback(result?.cashback);
      setcashBackProfit(result?.cashback_profit);

      // setCashbackDayofweek(result?.cashback_dayofweek);
      // setDayOfWeek(result?.day_of_week);
      // setDayText(result?.day_text);
    }
    setCashbackData(result);
    setLoading(false);
  };
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const claimCashback = async () => {
    setClaimLoading(true)

    try {
      const response: ApiResponseClaimCashbackData = await fetcher(
        !props?.cashback_type
          ? "/api/v2/users/claim-cashback"
          : `/api/v2/users/claim-cashback-type?cashback_type=${props?.cashback_type}`
      );
      setClaimLoading(false)

      if (response?.status !== true) {
        enqueueSnackbar(response?.msg || "พบข้อผิดพลาดจากระบบ กรุณาลองใหม่อีกครั้ง", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else {
        refresh();
        refreshPromotion();
        setCashbackData((prevState: any) => ({
          ...prevState,
          haveReceived: true
        }))
        enqueueSnackbar("สำเร็จ", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      setClaimLoading(false)
      enqueueSnackbar("พบข้อผิดพลาดจากระบบ กรุณาลองใหม่อีกครั้ง", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    fetchCashback();
  }, []);
  if (loading) {
    return (
      <>
        <Grid sx={{ textAlign: "center", color: (theme) => theme.gradient[900] }}>
          <CircularProgress></CircularProgress>
        </Grid>
      </>
    );
  }

  const cashbackType = cashbackData?.cashback_type === 'turnover' ? 'ยอดเล่น' : 'ยอดเสีย'
  const totalWinloseYesterday = cashbackData?.commissionList?.reduce((acc: number, value: any) => acc + value.winlose, 0)

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
      <Box>
        <Divider>
          <Chip
            label={`คืน${cashbackType}`}
            color="primary"
            size="medium"
            style={{ fontSize: "18px", fontWeight: 700 }}
          />
        </Divider>

        {/* <Box
          mt={2}
          flex="1"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image
            src={`https://images-platform.sgp1.cdn.digitaloceanspaces.com/general/828x274.jpg`}
            width={isMobile ? 300 : 400}
            height={isMobile ? 93.75 : 125}
            alt={'cashback'}
            style={{
              borderRadius: '16px',
              width: '100%',
              height: 'auto'
            }}
          />
        </Box> */}

        <Box display="flex" flexDirection={"row"} alignItems={"start"} justifyContent={'space-between'} mt={3}>
          <Typography variant="subtitle2" lineHeight={1.2} align="left" sx={{ color: (theme) => theme.gradient[920] }}>
            {`${cashbackType}สะสมในวันที่ผ่านมา: `}
            <span style={{ color: '#fdbd00' }}>{`${numeral(totalWinloseYesterday).format("0,0.00")}`}</span>
          </Typography>
          <Typography variant="subtitle2" lineHeight={1.2} align="left" sx={{ color: (theme) => theme.gradient[920] }} onClick={() => { setDialogOpen(true) }}>
            <span style={{ color: '#fdbd00', cursor: 'pointer' }}>รายละเอียด</span>
          </Typography>
        </Box>
        <Box display="flex" flexDirection={"column"} alignItems={"start"} mt={1}>
          <Typography variant="subtitle2" lineHeight={1.2} align="left" sx={{ color: (theme) => theme.gradient[920] }}>
            {`อัตราการคืนยอดเสีย: `}
            <span style={{ color: '#fdbd00' }}>{`${cashbackData?.commissionList[0].cashback_profit || '0'}%`}</span>
          </Typography>
        </Box>
        <Box mt={2}>
          <Divider sx={{ background: '#213743', borderWidth: '1px' }} />
        </Box>

        <Stack justifyContent={"center"} alignItems={"center"} mt={2}>
          <Typography align="center" color={(theme) => theme.gradient[900]}>
            {`คืน${cashbackType}สะสมปัจจุบัน`}
          </Typography>
          <Divider
            sx={{
              borderColor: theme.palette.primary.main,
              borderRadius: "10px",
              borderWidth: "1px",
              width: "20px",
            }}
          />
          <Typography
            align="center"
            fontSize="32px"
            fontWeight={"bold"}
            color={(theme) => theme.gradient[900] || theme.palette.primary.main}
          >
            {numeral(cashback).format("0,0.00")}
          </Typography>
        </Stack>
        <Box flex="1" display={"flex"} justifyContent={"start"} alignItems={"center"} mt={2} sx={{ width: '100%' }}>
          <LoadingButton
            disabled={cashbackData?.haveReceived ? true : false}
            onClick={claimCashback}
            type="submit"
            variant="contained"
            fullWidth
            loading={claimLoading}
            sx={{
              fontSize: '1.5rem',
              fontWeight: 700,
              color: '#fff',
              background: cashbackData?.haveReceived
                ? 'linear-gradient(0deg, rgba(3, 107, 34, 1) 0%, #6f7070 100%);'
                : 'linear-gradient(0deg, rgba(3, 107, 34, 1) 0%, rgba(0, 255, 76, 1) 100%);',
              height: "50px",
              "&:hover": {
                color: '#fff',
                background: cashbackData?.haveReceived
                  ? 'linear-gradient(0deg, rgba(3, 107, 34, 1) 0%, #6f7070 100%);'
                  : 'linear-gradient(0deg, rgba(3, 107, 34, 1) 0%, rgba(0, 255, 76, 1) 100%);',
              },
            }}
          >
            {
              cashbackData?.haveReceived
                ? (cashbackData?.agent_cashback_type === 'custom' && cashbackData?.cashback_conditon === 'week' && cashbackData?.day_of_week !== cashbackData?.cashback_day_of_week)
                  ? `รับ${cashbackType}ได้ทุก${cashbackData?.cashback_daytext}`
                  : `ทำรายการสำเร็จแล้ว`
                : `รับ${cashbackType}`
            }
          </LoadingButton>
        </Box>
        <Box mt={2}>
          <Alert icon={<Info fontSize="inherit" />} variant="outlined" severity="warning" sx={{
            // backgroundColor: (theme) => theme.gradient[930] ? theme.gradient[930] : "#1a2c38"
            background: 'linear-gradient(-325deg, rgba(250, 173, 20, 0.15) 100%, rgba(250, 173, 20, 0))'
          }}>
            {/* <AlertTitle sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
              {`กฏการรับกิจกรรมการถอน “${cashbackType}”`}
            </AlertTitle> */}
            <AlertTitle sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
              {`เงื่อนไขกิจกรรม “คืน${cashbackType}”`}
            </AlertTitle>
            <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
              - ลูกค้าจะต้องไม่กดรับ โบนัส ทุกประเภท
            </Typography>
            <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
              {
                (cashbackData?.agent_cashback_type === 'custom' && cashbackData?.cashback_conditon === 'week')
                  ? `- กดรับคืน “${cashbackType}” ได้ทุก${cashbackData?.cashback_daytext} เวลา 00.00-23.59น. หากไม่กดรับยอดภายในวันนั้น ยอดดังกล่าวจะถูกยกเลิกทันที`
                  : `- กดรับคืน “${cashbackType}” ได้หลังเวลา 00:00น. หากไม่กดรับยอดดังกล่าวจะถูกยกเลิกทันที`
              }
            </Typography>
            {/* <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
              {
                (cashbackData?.agent_cashback_type === 'custom' && cashbackData?.cashback_conditon === 'week')
                  ? `- กดรับคืน “${cashbackType}” ได้ทุก${cashbackData?.cashback_daytext} เวลา 00.00-23.59น. หากไม่กดรับยอดภายในวันนั้น ยอดดังกล่าวจะถูกยกเลิกทันที`
                  : `- กดรับคืน “${cashbackType}” ของยอดการแทงเมื่อวานได้ทุกวัน โดยระบบจะทำการคืน${cashbackType}ทุกวันตั้งแต่เวลา 00:00 น. เป็นเวลา 24 ชั่วโมง หากไม่กดรับยอดภายในวันนั้น ยอดดังกล่าวจะถูกยกเลิกทันที`
              }
            </Typography> */}
          </Alert>
        </Box>
      </Box>

      {/* <Grid xs={12} sm={12} md={12}>
        <Grid sx={{ textAlign: "center" }}>
          <Card>
            <CardActionArea onClick={claimCashback}>
              <CardContent component="div">
                <Typography sx={{ color: (theme) => theme.gradient[900] }}>ยอดเงินคืน</Typography>
                <SavingsOutlinedIcon
                  sx={{ fontSize: "3rem", color: (theme) => theme.gradient[900] }}
                ></SavingsOutlinedIcon>
                <Typography variant="h4" sx={{ textAlign: "center", color: (theme) => theme.gradient[900] }}>
                  {numeral(cashback).format("0,0.00")}
                </Typography>
                <Grid component="div">
                  <Chip sx={{ color: (theme) => theme.gradient[900] || "primary" }} label="ตรวจสอบยอดรับเสีย"></Chip>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid> */}

      {/* <Grid xs={12} sm={12} md={12}>
        <Grid sx={{ textAlign: "center",color: (theme) => theme.gradient[900] }}>
          <Typography>❗️ ยอดคืนสะสมจะรับได้ทุก{dayText} ❗️</Typography>
        </Grid>
      </Grid> */}

      {/* <Grid xs={12} sm={12} md={12}>
        <Grid sx={{ textAlign: "center" }}>
          <Card>
            <CardContent>
              <CurrencyExchangeOutlinedIcon
                sx={{ fontSize: "3rem", color: (theme) => theme.gradient[900] }}
              ></CurrencyExchangeOutlinedIcon>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>ขาดทุน</Typography>
              <Divider></Divider>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>{cashbackProfit} %</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Alert severity="warning" sx={{ backgroundColor: (theme) => theme.gradient[930] ? theme.gradient[930] : "#1a2c38" }}>
          <AlertTitle sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
            เงื่อนไขในการรับ
          </AlertTitle>
          <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
            - ลูกค้าจะต้องไม่กดรับ โบนัส ทุกประเภท
          </Typography>
          <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
            - QR code ใช้ได้เพียงครั้งเดียวเท่านั้น ห้ามใช้ซ้ำ หากสแกนซ้ำเข้ามา ทางเราไม่รับผิดชอบทุกกรณี
          </Typography>
        </Alert>
      </Box> */}

      <SnackbarWithIcon
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
        vertical={vertical}
        horizontal={horizontal}
      />

      <TermsDialog
        wheelData={null}
        open={dialogOpen}
        closeDialog={() => { setDialogOpen(false) }}
      />
    </Grid>
  );
};

export default Cashback;
